var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "VipcnDrainage_wrapper" },
    [
      _c(
        "el-form",
        { attrs: { model: _vm.PublicAccountDetail, "label-width": "100px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "公众号名称", prop: "name" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入公众号名称",
                  maxlength: "20",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.PublicAccountDetail.wxMqName,
                  callback: function($$v) {
                    _vm.$set(_vm.PublicAccountDetail, "wxMqName", $$v)
                  },
                  expression: "PublicAccountDetail.wxMqName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "up_poster", attrs: { label: "公众号二维码" } },
            [
              _c(
                "label",
                {
                  staticClass: "el-upload el-upload--picture-card",
                  attrs: { for: "brand-logo" }
                },
                [
                  _c("input", {
                    ref: "brandlogo",
                    attrs: {
                      type: "file",
                      hidden: "",
                      id: "brand-logo",
                      "data-type": "brandLogo",
                      name: "upload",
                      accept: "image/*"
                    },
                    on: { change: _vm.uploadImage }
                  }),
                  _vm.PublicAccountDetail.wxMqQrcode
                    ? _c("img", {
                        staticStyle: { width: "142px", height: "142px" },
                        attrs: { src: _vm.PublicAccountDetail.wxMqQrcode }
                      })
                    : _c("i", {
                        staticClass: "el-icon-plus avatar-uploader-icon"
                      })
                ]
              ),
              _c("span", { staticClass: "ml10 form_tips" }, [
                _vm._v("建议尺寸300x300 px，支持JPG，PNG，JPEG格式")
              ])
            ]
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitPublicAccount }
                },
                [_vm._v("保 存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }