<template>
  <div class="VipcnDrainage_wrapper">
    <el-form :model="PublicAccountDetail" label-width="100px">
      <el-form-item label="公众号名称" prop="name">
        <el-input
          v-model="PublicAccountDetail.wxMqName"
          placeholder="请输入公众号名称"
          maxlength="20"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="公众号二维码" class="up_poster">
        <label for="brand-logo" class="el-upload el-upload--picture-card">
          <input
            type="file"
            ref="brandlogo"
            hidden
            id="brand-logo"
            data-type="brandLogo"
            name="upload"
            accept="image/*"
            @change="uploadImage"
          />
          <img
            v-if="PublicAccountDetail.wxMqQrcode"
            :src="PublicAccountDetail.wxMqQrcode"
            style="width:142px;height:142px;"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </label>
        <span class="ml10 form_tips">建议尺寸300x300 px，支持JPG，PNG，JPEG格式</span>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitPublicAccount">保 存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import MarketingAjax from "@/utils/https/modules/Marketing.request.js";
import ActivityAjax from "@/utils/https/modules/Activity.request.js";
import VzanUpload from "@/utils/https/VzanUpload.js";
export default {
  name: "VipcnDrainage", // 活动-营销-公众号引流设置
  components: {},
  props: {
    activityID: String
  },
  data() {
    return {
      VipcnDrainageForm: {
        name: "",
        poster: ""
      },
      PublicAccountDetail: {
        actId: 0,
        wxMqName: "",
        wxMqQrcode: ""
      }
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 上传
    async uploadImage(event) {
      try {
        const { src } = await this.upload.uploadImage(event, {
          maxSize: 4
        });
        this.PublicAccountDetail.wxMqQrcode = src;
      } catch (error) {
        error && this.$message.warning(error.message);
      }
    },
    // 公众号引流设置，传入活动id(尚未创建时返回null）
    async getPublicAccount() {
      try {
        const data = await MarketingAjax.getPublicAccount(this.activityID);
        if (data.data) {
          this.PublicAccountDetail = data.data;
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 确认新建或编辑公众号引流设置
    async submitPublicAccount() {
      this.PublicAccountDetail.actId = this.activityID;

      let act = this.PublicAccountDetail;
      console.log(act);
      await MarketingAjax.savePublicAccount(act);
      this.$message.success("编辑公众号引流设置成功");
      await this.getPublicAccount();
    }
  },
  created() {
    this.getPublicAccount();
  },
  mounted() {
    this.$nextTick(() => {
      // 初始化 上传
      this.upload = new VzanUpload({
        actId: 0,
        category: "common",
        extension: "jpg",
        fileType: "image"
      });
    });
  }
};
</script>
<style lang="scss" scoped>
.VipcnDrainage_wrapper {
}
</style>
